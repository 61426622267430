.confirmation-dialog {
  background-color: #6dc089; /* Green background */
  padding: 20px;
  text-align: center;
  border-radius: 8px;
}

.confirmation-dialog-content {
  display: contents;
  background: #6dc089;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  color: rgb(0, 0, 0);
}

.confirmation-dialog-content h2 {
  margin-top: 2%;
}

.check-icon {
  width: 17%;
}

.confirmation-heading {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin: 16px 0 8px;
  color: #222;
}

.confirmation-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  margin: 12px 0 0;
  color: #333;
}

.confirmation-subtext {
  margin: 5px 0 0 0;
}

.course-card {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: white;

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    flex-direction: column;
    align-items: normal;
  }
}

.course-image {
  width: 29%;
  height: 29%;
  border-radius: 8px;
  margin-right: 20px;

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    width: 100%;
    height: 50%;
  }
}

.course-details {
  text-align: left;
}

.course-details h3 {
  margin: 0;
  color: #612ef4;
  font-size: x-large;
}

.course-details p {
  margin: 5px 0 0 0;
  color: #6c6c6c;
  font-weight: 500;
}

.link-button {
  display: inline-block;
  background-color: #ff67a1 !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 30px !important;
  box-shadow: 0px 5px 7px 0 rgba(0, 0, 0, 0.3) !important;
  text-transform: none !important;
  border-radius: 10px !important;
  color: white;
  border: none;
  padding: 5px 20px;
}

.link-button-wrapper {
  text-decoration: none;
}

.whatsapp-button-wrapper {
  display: flex;
  justify-content: center;
}
