.whatsapp-button {
  display: flex;
  align-items: center;
  background-color: #1ebe5d;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: fit-content;
}

.whatsapp-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.whatsapp-button:hover {
  background-color: #25d366;
}
