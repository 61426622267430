.card{
    background:#F5F5F5;
    height: auto;
    min-height: 70%;
    width: 60%;
    display: flex;
    border-radius: 25px;
    position: fixed;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    backface-visibility: calc(50%);
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
.eventimage{
    width: -webkit-fill-available;
}
.datequantity{
    display: flex;
    justify-content: space-between;
}
.subtitle{
    margin: 0;
    font-size: 12px;
    font-family: Poppins;
    color: #BCBCBC;
}
.subtotal{
    display: flex;
    justify-content: space-between;
    color: #000000;
    font-family: Poppins;
    font-weight: 500;
}

.couponsave{
    display: flex;
    justify-content: flex-start;
    background: #e9e4f9;
    padding: 16px;
    border-radius: 4px;
    color: #7A53FF;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
}
.couponHandler{
    display: flex;
    width: 100%;
}
.counter{
    display: flex;
    gap: 8px;
}
.counters{
    margin: 0;
}
.entryticket{
    margin: 0;
}
.counterholder{
    display: flex;
    font-family: Poppins;
    align-items: center;
    justify-content: space-between;
}
.total{
    display: flex;
    justify-content: space-between;
    color: #000000;
    font-family: Poppins;
    font-weight: 600;
}
.ticketdate{
    width: 50%;
}
.btnback{
    height: auto;
    background: #D4D4D4;
    color: #000000;
    border: none;
    font-family: Poppins, sans-serif;
    padding: 12px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    width: 30%;
}
.btnnext{
    height: auto;
    width: 100%;
    background: #FF67A1;
    color: #FFFFFF;
    border: none;
    font-family: Poppins, sans-serif;
    padding: 12px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 20px;
    margin-top: auto;
}

.btnholder{
    display: flex;
    gap: 8px;
    margin-top: auto;
}
.ticket{
    position: absolute;
    width: 102%;
    bottom: -25px;
    left: -5px;
    padding: 2px;
}
.progressbar{
    display: flex;
}
.active{
    background: #7A53FF;
    height: 12px;
    border-radius: 8px;
    width: 50%;
    margin: 4px;

}
.closebtn{
    width: 18px;
    height: 18px;
    padding: 4px;
    position: absolute;
    top: -28px;
    right: 0;
    background: #ffffff;
    border-radius: 50%;
}
.inactive{
    background: #D9D9D9;
    height: 12px;
    border-radius: 8px;
    width: 50%;
    margin: 4px;
}
.quantity{
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    margin: 0;
}
.imgholder{
    padding: 20px 20px 0px 20px;
    margin: auto;
}
.leftcontent{
    width: 50%;
    position: relative;
}
.carddetails{
    width:70%;
    margin:20px auto 60px;
    height: auto;
    border-radius: 18px 18px 0 0;
    background: #FFFFFF;
}
.rightcontent{
    width: 50%;
    position: relative;
    display: flex;
    background: #FFFFFF;
    border-radius: 18px;
    padding: 20px 20px 60px 20px;
    flex-direction: column;
    /* justify-content: space-between; */
}
.list{
    list-style-type: none;
    padding: 0px 12px;
}
.listdetails{
    display: flex;
    gap: 8px;
    padding: 0px;
    align-items: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    color: #737373;
}
.details{
    color: #737373;
    font-weight: 500;
    font-family: Poppins;
    font-size: 16px;
    margin-bottom: 0;
}

.price{
    color: #737373;
    font-weight: 500;
    font-family: Poppins;
    margin: 0;
    font-size: 16px;
}


.ticketquantity{
    height: auto;
    padding: 20px;
    margin-right: 20px;
    width: -webkit-fill-available;
    top: 80px;
    position: absolute;
    border-radius: 20px;
    border: 1px solid #D1D1D180;
}

.cardholder{
    display: flex;
    width: 100%;
}

.paymentdetails{
    padding: 20px 20px 20px;
    position: relative;
    height: min-content;
}
.ticketbtn{
    display: none;
}
.eventheading{
    margin: 0;
    font-weight: 500;
    font-family: Poppins;
    font-size: 16px;
}
@media (max-width:780px) {
    .ticket{
        padding: 4px;
        left: -9px;
        bottom: -36px;
    }
    .card{
        flex-direction: column;
        width: 80%;
        height: 100vh;
    }
    .closebtn{
        top: 4px;
        z-index: 999;
        right: 8px;
    }
    .ticketquantity{
        position: static;
        margin-right: 0px;
    }
    .leftcontent{      
        width: 100%;
        display: flex;
       flex-direction: column;
    }
    .rightcontent{
        width: 80%;
        margin: auto;
        gap: 8px;
        
    }

    .paymentdetails{
       box-shadow:  0px 4px 8px rgba(0, 0, 0, 0.1);
       border-top-left-radius: 20px;
       border-top-right-radius: 20px;
    }
    .carddetails{
        left: 12%;
    }

    .cardholder{
        flex-direction: column-reverse;
        overflow: auto;
    }
    
}

@media (max-width: 480px) {
    .card {
        flex-direction: column;
        width: 100%;
        background: #FFFFFF;
    }
    .ticket{
        left: -6px;
        padding: 4px;
        bottom: -22px;
    }
    .closebtn{
        top: -14px;
    }

    .carddetails{
        margin-bottom:120px
    }

    .btnback {
        padding: 4px;
        font-size: 16px;
    }

    .btnnext {
        padding: 8px;
        font-size: 16px;  
    }
    .ticketbtn{
        display: flex;
        position: absolute;
        bottom: 8%;
        right: 30%;
        border: navajowhite;
        color: black;
        padding: 8px;
        background: #D4D4D4;
        font-family: 'Poppins';
        border-radius: 10px;
        width: 40%;
        font-size:12px;
        margin: auto;
        justify-content: center;
    }

    .rightcontent {
        width: 90%;
        gap: 8px;
        left: 0px;
        height: auto;
    }

    .fixedfooter {
        position: fixed;
        bottom: 0; /* Ensures it's always at the bottom */
        left: 0;
        width: 100%; /* Takes full width of the screen */
        background: #fff;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        z-index: 1000;
        margin: 4% 0px;
    }

    .fixedfooter .btnback,
    .fixedfooter .btnnext {
        flex: 1;
        margin: 0 0px; /* Adds spacing between buttons */
        font-size: 16px;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        border: none;
    }

    .fixedfooter .btnnext {
        background-color: #FF67A1;
        color: white;
        border-radius:0px
    }

    .fixedfooter .btnback {
        background-color: #D4D4D4;
        color: black;
    }

    /* Hide the default back button if it's already styled elsewhere */
    .btnback {
        display: none;
    }
}
