.workshop-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px;
  flex-wrap: wrap;
}

.workshop-left {
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 65%;
}

.video-player-wrapper {
  width: 100%;
  max-width: 100%; /* Prevent it from going outside of the screen */
  position: relative;
  margin: 0 auto;
}

.video-player {
  width: 100%;
  height: 400px;
  background-color: #000;
}

.video-player-wrapper iframe {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
}

.video-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

.video-author {
  font-size: 18px;
  color: gray;
}

.workshop-right {
  flex: 0.3;
}

.workshop-card-right {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 5%;
}

.workshop-section {
  margin-bottom: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.section-header h4 {
  margin: 0;
  font-size: 18px;
}

.section-content {
  padding: 10px;
}

.chapter {
  padding: 10px;
  cursor: pointer;
  background-color: #f1f1f1;
  margin: 5px 0;
  border-radius: 6px;
}

.chapter:hover {
  background-color: #ddd;
}

.chapter.active {
  background-color: #ccc;
}

@media (max-width: 768px) {
  .workshop-container {
    flex-direction: column;
    margin: 0;
  }

  .workshop-left {
    max-width: 100%;
    margin-right: 0;
  }

  .video-player {
    height: 300px;
  }

  .workshop-right {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 460px) {
  .video-player-wrapper {
    width: 460px;
  }
  .video-player-wrapper iframe {
    width: 100%;
    height: 250px;
  }
}

@media (max-width: 445px) {
  .video-player-wrapper {
    width: 445px;
  }
  .video-player-wrapper iframe {
    width: 100%;
    height: 250px;
  }
}

@media (max-width: 430px) {
  .video-player-wrapper {
    width: 430px;
  }
  .video-player-wrapper iframe {
    width: 100%;
    height: 250px;
  }
}

@media (max-width: 415px) {
  .video-player-wrapper {
    width: 415px;
  }
  .video-player-wrapper iframe {
    width: 100%;
    height: 250px;
  }
}

@media (max-width: 390px) {
  .video-player-wrapper {
    width: 390px;
  }
  .video-player-wrapper iframe {
    width: 100%;
    height: 250px;
  }
}
