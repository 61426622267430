/* General Styles */
.Event-page {
    width: 80%;
    margin: auto;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.event-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: auto;
}

.booking-btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-highlight {
    font-family: Poppins, sans-serif;
    font-weight: 700;
}

.text-italic {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-style: italic;
}

.carousal-slider {
    display: flex;
    gap: 32px;
    overflow: hidden; /* Prevents content overflow */
    white-space: nowrap; /* Prevents wrapping */
    animation: move-horizontal 25s linear infinite;
    width: max-content;
}

.image-holder {
    height: auto;
    width: 60%;
}

.content-wrapper {
    width: 40%;
}

.content-div {
    width: 100%;
    padding: 20px;
    height: auto;
    border-radius: 14px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: center;
}

.event-img {
    border-radius: 22px;
    width: 90%;
}

.event-details {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq {
    padding: 40px;
    border-radius: 14px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pop-600 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
}

.fs-24 {
    font-size: 24px;
    margin: 0;
    font-weight: 400;
    font-family: Roboto, sans-serif;
}

.fs-32 {
    font-size: clamp(1.5rem, 10vh, 2rem);
    margin: 0;
}

.event-desc {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 14px;
    overflow-y: auto;
}

.map-direction {
    width: 40%;
    height: 100%;
    padding: 20px;
    display: flex;
    gap: 20px;
    border-radius: 14px;
    flex-direction: column;
    align-items: center;
}

.book-ticket-btn {
    display: flex;
    align-items: center;
    height: 10vh;
    justify-content: center;
}

.brand-img {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    margin: 0 10px;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
}

.brand-carousel-track {
    display: flex;
    animation: loop-carousel 25s linear infinite;
    will-change: transform;
    width: max-content;
}
.btn-direction {
    width: 50%;
    background-color: #7A53FF;
    color: #FFFFFF;
    text-decoration: none;
    padding: 12px;
    border-radius: 8px;
    border: none;
    font-family: Poppins, sans-serif;
    font-size: 16px;
}

.brand-carousal {
    padding: 20px 0;
}

.blue-header {
    color: #602EF4;
    font-size: 40px;
    font-family: Montserrat, sans-serif;
    margin: 0 0 20px;
    font-weight: 700;
}

.what-to-expect-container {
    display: flex;
    gap: 20px;
    white-space: nowrap;
    width: max-content;
    transition: transform 0.5s ease-in-out;
    position: relative;
    scroll-snap-type: x mandatory;
}

.container-img {
    scroll-snap-align: start;
}

.what-to-expect-section {
    padding: 20px;
    border-radius: 14px;
}



.wrapper-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;
}

.img-style {
    height: 360px;
    width: 260px;
    filter: brightness(0.6);
    border-radius: 22px;
}

.container-img {
    position: relative;
    min-width: 200px;
    margin: 0 10px;
    transition: transform 1s ease-in-out;
}

.title-desc {
    position: absolute;
    top: 8%;
    left: 4%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 80%;
    overflow: hidden;
    padding: 10px;
}

.description {
    color: #FFFFFF;
    word-wrap: break-word;
    line-height: 1.5;
    width: 100%;
    white-space: normal;
    word-break: break-word;
}

.title-style {
    color: #F8C530;
    word-break: break-word;
    white-space: normal;
    word-wrap: break-word;
}

.event-content {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.booking-btn {
    height: auto;
    background: #FF67A1;
    color: #FFFFFF;
    border: none;
    font-family: Poppins, sans-serif;
    padding: 12px;
    border-radius: 10px;
    font-weight: 600;
    width: 30%;
    font-size: 20px;
}

.sm-btn {
    width: 60%;
    font-size: 16px;
}

.points {
    font-size: clamp(1rem, 10vh, 1.2rem);
}

.tandc {
    width: 100%;
}

.what-to-expect-container:hover {
    animation-play-state: paused;
}

.nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: #fff;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    transition: background-color 0.3s;
}

.nav-btn:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.nav-btn.left {
    left: 10px;
}

.nav-btn.right {
    right: 10px;
}

@keyframes loop-carousel {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);; 
    }
}


/* Media Queries */
@media (max-width: 768px) {
    .event-container {
        flex-direction: column;
        height: auto;
        gap: 15px;
    }

    .content-wrapper {
        width: 100%;
    }

    .content-div {
        width: 80%;
    }

    .image-holder {
        width: 100%;
    }

    .event-img {
        max-height: 250px;
    }

    .event-details {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    .event-desc, .map-direction {
        width: 90%;
    }

    .brand-carousal {
        padding: 10px 15px;
    }

    .what-to-expect-container {
        gap: 10px;
    }

    .blue-header {
        font-size: 28px;
    }

    .booking-btn {
        width: auto;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .event-container {
        flex-direction: column;
        height: auto;
        gap: 10px;
    }

    .Event-page {
        width: 90%;
    }

    .sm-btn {
        font-size: 12px;
    }

    .faq {
        padding: 0;
    }

    .btn-direction {
        font-size: 12px;
    }

    .event-content {
        font-size: 12px;
        text-align: center;
    }

    .book-ticket-btn {
        font-family: Poppins, sans-serif;
    }

    .content-div {
        width: 100%;
    }

    .event-img {
        max-height: 200px;
        width: 100%;
    }

    .event-details {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .event-desc, .map-direction {
        width: 100%;
        height: auto;
        align-items: center;
    }

    .brand-carousal {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .what-to-expect-container {
        gap: 10px;
    }

    .blue-header {
        font-size: 20px;
        text-align: center;
    }
}
